$(document).on("turbolinks:load", function() {
  $(function() {
    $('.js-item-caroucel').slick({
      infinite: false,
      variableWidth: true,
      arrow: true
    });
    $('.js-product-gallery-slider').slick({
      infinite: false,
      fade: true,
      dots: true,
      arrow: true
    });
    $('.js-gallery-modal-open').on('click', function(){
      $('.js-product-gallery-slider').resize();
    });
  });
});
