$(document).on('turbolinks:load', function(){
  $('.js-modal-open').on('click',function(){
    modal = $(this).next().find('.js-modal');
    $(modal).fadeIn(10);
    return false;
  });
  $('.js-modal-close').on('click',function(){
    $('.js-modal').fadeOut(10);
    return false;
  });
});
