$(function() {
  const menuToggle = document.querySelector(".js-menu-toggle");
  const wholeDocument = document.body;
  const target = document.querySelector(".js-menu-content");

  let toggleOpen = false;

  menuToggle.addEventListener('click', (e) => {
    if (!toggleOpen) {
      target.classList.add('header-open');
      menuToggle.classList.add('toggle-on');
      toggleOpen = true;
    } else {
      target.classList.remove('header-open');
      menuToggle.classList.remove('toggle-on');
      toggleOpen = false;
    }
  });

  target.addEventListener('click', (e) => {
    if (!toggleOpen) {
      target.classList.add('header-open');
      menuToggle.classList.add('toggle-on');
      wholeDocument.style.overflow = "hidden";
      toggleOpen = true;
    } else {
      target.classList.remove('header-open');
      menuToggle.classList.remove('toggle-on');
      wholeDocument.style.overflow = "visible";
      toggleOpen = false;
    }
  });
});
