$(document).on('turbolinks:load', function(){
  $(".tag-field").autocomplete({
    source: "/api/tags",
    autoFocus: true,
    minLength: 1,
    delay: 200
  })
});

$(document).on('turbolinks:load', function(){
  set_deletes();

  $('#js-add-tag').on('click', function(){
    tag_items = $('.tag-item');
    values = tag_values();
    if(values.length == tag_items.length) {
      $(".tag-alert:not(:animated)").fadeIn("slow" ,function(){
        $(this).delay(1000).fadeOut("slow");
      });
    } else {
      if($('.tag-field').val() != '') {
        target = $(tag_items[values.length]);
        target.val($('.tag-field').val());
        target.next().show();  
      }
    }
  });

  $('.tag-delete').on('click', function(){
    $(this).prev().val('');
    set_tags();
  });

  function tag_values() {
    tag_items = $('.tag-item');
    values = [];
    $.each(tag_items, function(index, item) { 
      if($(item).val() != '') {
        values.push($(item).val());
      }
    });
    return values;
  };

  function set_deletes() {
    $.each($('.tag-delete'), function(index, elem) {
      if($(elem).prev().val() == '') {
        $(elem).hide();
      }
    });  
  }

  function set_tags() {
    tag_items = $('.tag-item');
    values = tag_values();
    $.each(tag_items, function(index, item) {
      if(values[index] != '') {
        $(item).val(values[index]);
      } else {
        $(item).val('');
      }
    });  
    set_deletes();
  }
});